import { setDefaultOptions } from 'date-fns';
import i18n, { FallbackLngObjList } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { SET_PHONE_LANGUAGE } from 'Reducers/env/types';

import * as log from 'Utilities/log';
import mediator from 'Utilities/mediator';
import { updateChosenLang } from 'Utilities/storage';
import { store } from 'Utilities/store';

const namespace = 'translations';
let initialized = false;
let firstLangSet = false;
let currentLang = '';
/**
 * Construct an ordered array of unique languages to support based on the passed in language.
 * The order is constructed with the original language, followed by its corresponding fallback languages
 * ending with our default languages.
 *
 * This ordered structure is handy for both i18n and date-lib
 */
const _getSupportedLangs = (lang: string): string[] => {
  // This should be the configurable list of supported languages
  const langFallBack = {
    default: ['en-US'],
    en: ['en-US'],
    'es-ES': [],
    es: ['es-ES'],
    'es-US': ['es-ES'],
  } as {
    default: string[];
    [key: string]: string[];
  };
  let fallbacks = langFallBack[lang] || [];
  const langs = lang ? [lang] : [];

  // Add 2 char fallbacks after 5 char fallbacks
  fallbacks = fallbacks.concat(langFallBack[lang.substr(0, 2)] || []);

  return [...new Set([...langs, ...fallbacks, ...langFallBack['default']])];
};

// this is to getLocale to date-lib
const getLocale = (locale: string[]): Locale => {
  for (let i = 0; i < locale.length; i++) {
    const localeString = locale[i];

    try {
      // try to see if module exist, if exist return a string or return a error
      const moduleExist = require.resolve(`date-fns/locale/${localeString}/index.js`);

      // this if not need,i can return directly, but i put to be more semantic
      if (moduleExist) {
        // if module exist return;
        return require(`date-fns/locale/${localeString}/index.js`);
      }
    } catch (ex) {
      //  if module not exist so he log and go to next item in array of locale
      log.debug(JSON.stringify(ex));
      continue;
    }
  }

  // if none of locales in array was found he return en-US as fallback
  return require(`date-fns/locale/en-US/index.js`);
};

/**
 * This builds an array of language codes that date-lib will use to properly set the local
 * Places 2 code locales in the array after 5code locales in a strategic way
 *
 * @param {*} langs list of langs from _getSupportedLangs
 */
const _dateLibList = (langs: string[]) => {
  const createdList = [];

  for (let i = 0; i < langs.length; i++) {
    let firstCode;
    let secondCode;

    if (langs[i].length === 5) {
      firstCode = langs[i].slice(0, 2);
    }

    if (i + 1 < langs.length && langs[i + 1].length === 5) {
      secondCode = langs[i + 1].slice(0, 2);
    }

    createdList.push(langs[i]);

    if (firstCode && firstCode !== secondCode) {
      createdList.push(firstCode);
    }
  }

  return createdList;
};

/**
 * Takes a languages array and attempts to find a translation file that matches each language.
 * We build an array that contains all successfully loaded translations in the same relative order.
 */
const _requireTranslation = (langs: string[]) => {
  const locales = [];

  for (const lang of langs) {
    if (process.env.NODE_ENV === 'production') {
      // Do not load the baseline 'en' translations on production
      if (lang === 'en') {
        continue;
      }
    }

    try {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const locale = require(`../locales/${lang}/translations.json`);

      locales.push({
        locale,
        lang: lang,
      });
    } catch (e) {
      log.error(e);
    }
  }

  return locales;
};

// Handle setting locale and adding any locale overrides
const _handleDateLib = (lang: string) => {
  // Use the same language fallback structure
  const codesLocale = _dateLibList(_getSupportedLangs(lang));
  const locale = getLocale(codesLocale);

  setDefaultOptions({
    locale,
  });

  log.debug(`dateFns: local set to ${locale.code} using ${lang}`);
};

// Set the HTML Document title based on langauge
const _changeDocumentTitle = () => {
  window.document.title = i18n.t('Global.microcopy.common.title');
};

// Set the HTML Document direction attribute based on language
const _changeHtmlDirection = (lang: string) => {
  let dir = 'ltr';

  switch (lang.slice(0, 2)) {
    case 'ar':
    case 'he':
      dir = 'rtl';
  }

  document.documentElement.dir = dir;
  log.debug(`html: setting document direction to ${dir}`);
};

// Set i18n language and set date library global locale support to be simmilar
const _setLanguage = (lang: string) => {
  i18n.changeLanguage(lang, (err) => {
    if (err) {
      log.error(err);
    } else {
      updateChosenLang(lang);
      _changeDocumentTitle();
      _changeHtmlDirection(lang);
      _handleDateLib(lang);
      currentLang = lang;

      if (store) {
        store.dispatch({ type: SET_PHONE_LANGUAGE, phoneLanguage: lang });
      }

      // Make sure it isn't the crowdin inContext language
      if (lang !== 'la-LA') {
        mediator.publish('langChange:success', { lang });
      }

      // Let the app know i19n is ready to be used
      if (!firstLangSet) {
        firstLangSet = true;
        mediator.publish('app:ready', { i18n: true });
      }
    }
  });
};

// Handle importing all valid languages that the passed in language supports and set the app language
const _handleChange = (lang: string) => {
  if (!initialized) {
    return;
  }

  if (lang === currentLang) {
    return;
  }

  const setLocales = _requireTranslation(_getSupportedLangs(lang));

  if (setLocales.length === 0) {
    log.panic('Unable to find any valid translation resource');
  }

  // We load all possible locales into i18n that we can, so i18n can handle it's internal missing key fallbacks
  for (const l of setLocales) {
    if (!i18n.hasResourceBundle(l.lang, namespace)) {
      i18n.addResourceBundle(l.lang, namespace, l.locale);
    }
  }

  // We use the first avaiable locale as the language to set
  _setLanguage(setLocales[0].lang);
};

/**
 * Callback method to trigger after i18n has initialized.
 */
const initComplete = () => {
  initialized = true;

  // Use the user's langauge or detected browser language
  const urlParams = new URLSearchParams(window.location.search);
  const locale = urlParams.get('locale') || urlParams.get('lang') || i18n.language;

  _handleChange(locale);
};

// Want to wait until the store is ready to set up the subscription and initialize i18n so we can use config values.
const init = () => {
  const {
    langFallBack = {
      default: ['en-US'],
      en: ['en-US'],
      'es-ES': [],
      es: ['es-ES'],
      'es-US': ['es-ES'],
    },
    langWhiteList = ['en', 'en-US', 'ar', 'es', 'es-ES', 'es-US'],
  } = {};

  // We append default to the end of all fallbacks for i18n key resolution
  const i18nLangFallback = Object.entries(langFallBack).reduce((pv, [k, v]) => {
    const langs = [...v];

    if (langFallBack.default) {
      langFallBack.default.forEach((l: string) => {
        if (langs.indexOf(l) === -1) {
          langs.push(l);
        }
      });
    }

    pv[k] = langs;
    return pv;
  }, {} as FallbackLngObjList);

  i18n.use(LanguageDetector).init(
    {
      cleanCode: true,
      debug: process.env.NODE_ENV !== 'production',
      defaultNS: namespace,
      detection: {
        caches: [],
        order: ['querystring', 'navigator'],
      },
      fallbackLng: i18nLangFallback,
      interpolation: {
        escapeValue: false,
      },
      nonExplicitSupportedLngs: true,
      ns: [namespace],
      supportedLngs: langWhiteList.length > 0 ? langWhiteList : false,
    },
    initComplete
  );
};

mediator.once('init:i18n', init);

// Want to wait until the store is ready to set up the subscription and initialize i18n so we can use config values.
mediator.once('store:created', () => {
  init();

  // observeStore((state) => state.user.uiLanguage, _handleChange);
});

mediator.subscribe('trans:change', _handleChange);

export default i18n;
